import { MaskOnBlur } from "utils";

export default {
    FRENCH: {
        DESCRIPTION: "Description",
        FREE_IN_N_HOURS_FROM: (days = 2, price = 300, symbol, precision) => `DÉLAI DE LIVRAISON ${days}j`,/*H À PARTIR DE ${MaskOnBlur(price,symbol,precision)} */
        ADD_TO_CART: 'Ajouter au panier',
        CART: "Panier",
        REFERENCE: (ref) => `Référence : ${ref}`,
        SIGN_IN: 'Connexion',
        ALREADY_HAVE_AN_ACCOUNT: 'Vous avez déjà un compte ? ',
        CREATE: 'Créer',
        PASSWORD: 'Mot de passe',
        EMAIL: 'Email',
        LAST_NAME: 'Nom',
        FIRST_NAME: 'Prénom',
        FIELD_REQUIRED: "Ce champ est obligatoire",
        INVALID_EMAIL_ADDRESS: "Adresse e-mail non valide",
        CUSTOMER_LOGIN: 'Connectez-vous',
        NEW_CUSTOMER_SIGN_UP: "Nouveau client? S'enregistrer!",
        ACCOUNT: 'Compte',
        CREATE_ACCOUNT: 'Créer un compte',
        LANGUAGE: 'Langue',
        ENGLISH: 'Anglais',
        FRENCH: 'Français',
        FORGOT_PASSWORD: "Mot de passe oublié ?",
        TITLE_FORGOT_PASSWORD: "Mot de passe oublié",
        NEXT: "Suivant",
        ENTER_CODE: "Saisir le code",
        CONFIRMATION: "Confirmation",
        CHANGE: "Changer",
        PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS: "Mot de passe doit être au moins de 6 caractères",
        PASSWORDS_ARE_NOT_THE_SAME: "Les mots de passe ne sont pas identiques",
        PLEASE_CONFIRM_YOUR_NEW_PASSWORD: 'Veuillez confirmer votre nouveau mot de passe',
        NEW_PASSWORD: "Nouveau mot de passe",
        IS_COMPANY: 'Société',
        "IS_COMPANY_?": 'Client société ?',
        COMPANY_NAME: "Raison sociale",
        MY_CART: "Mon panier",

        CELLPHONE: "Mobile",
        CREATE_AN_ACCOUNT: "Créer un compte",
        OPERATION_CHANGE_PASSWORD_SUCCESS: 'Votre mot de passe a été modifié avec succès !',
        OPERATION_SUCCESS: "Opération effectuée avec succès",
        PRODUCTS_LEFT: (stoke = 0, withNegativeStock = false, articleId) => withNegativeStock && articleId ? 'En stock' : (stoke > 0 && articleId) ? `Il reste ${stoke} produits` : 'Epuisé',
        SHOP_NOW: "Acheter",
        SEARCH: "Chercher",
        PRODUCT: 'Produit',
        TOTAL: 'Total',
        QTY: 'Qté',
        REMOVE: 'Supprimer',
        CHECK_OUT: "Passer à la caisse",
        VALIDATE_CART: "Valider le panier",
        PROCEED_TO_PAYMENT: "Passer au paiement",
        ARTICLE_NOT_FOUND: "Article non trouvé",
        ARTICLE_NOT_ACTIF: "Article non actif",
        ARTICLE_NOT_AVAILABLE_FOR_SALE: "Article non disponible à la vente",
        ARTICLE_NOT_AVAILABLE_ONLINE: "Article non disponible en ligne",
        WEB_ONLINE_START_DATE_BEFORE_TODAY: "L’article n’est pas disponible actuellement",
        WEB_ONLINE_DEADLINE_EXPIRED: "L’article n’est pas disponible actuellement",
        ARTICLE_TYPE_NOT_SIMPLE: "Type d'article non simple",
        ADRESS_1_FORM: "Adresse 1",
        ADRESS_2_FORM: "Adresse 2",
        ADRESS_3_FORM: "Adresse 3",
        CITY_FORM: "Ville",
        POSTAL_CODE_FORM: "Code postal",
        COUNTRY: "Pays",
        VALIDATE: 'Valider',
        CANCEL: 'Non, annuler',
        BILLING_ADDRESS: 'Adresse de facturation',
        DELIVERY_ADDRESS: 'Adresse de livraison',
        MY_ACCOUNT: "Mon compte",
        ECOMMERCE_IS_NOT_CONFIGURED_FOR_THIS_STORE: "E-commerce n'est pas configuré",
        SOMETHING_WENT_WRONG: "Oups, quelque chose s'est mal passé !",
        REFRESH: "Actualiser",
        OPERATION_FAILED: "Opération échouée",
        DECLINATION_PRINCIPLE_PRICE: ({ MaskOnBlur = () => null,
            minDeclinationPrinciplePriceInclTax,
            maxDeclinationPrinciplePriceInclTax, symbol, precision }) => `De ${MaskOnBlur(minDeclinationPrinciplePriceInclTax, symbol, precision)} à ${MaskOnBlur(maxDeclinationPrinciplePriceInclTax, symbol, precision)}`,

        DELETE_LIGNE: "Oui, supprimer la ligne",
        CANCEL_LIGNE: "Non, annuler",
        CONFIRM_DIALOG_TITLE_BLOCK: "Etes vous sûr de vouloir supprimer la ligne ?",
        CONFIRM_VALIDATION_BUTTON: "Oui, valider",
        WOULD_YOU_REALLY_LIKE_TO_CONFIRM_YOUR_CART: "Voulez-vous vraiment valider votre panier ?",
        YOU_HAVE_NO_ITEMS_IN_YOUR_CART: "Vous n'avez aucun article dans votre panier",
        LOGOUT: "Me déconnecter",
        COPY_INTO_THE_DELIVERY_ADDRESS: "Copier dans l'adresse de livraison",
        NO_TEXT_LABEL: "Aucune option",
        PRODUCT_ADDED_SUCCESSFULLY: "Produit ajouté avec succès",
        ERROR_AVAILABLE_STOCK: "Stock Indisponible",
        ERROR_SELECT_VARIANT_VALUE: "Sélectionner une option dans la liste disponible des valeurs de variables",
        HOME: "Accueil",
        YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED: "Votre paiement a été effectué avec succès",  YOUR_CANCEL_PAYMENT_LINK_HAS_BEEN_SUCCESSFULLY_PROCESSED:"Lien de paiement annulé avec succès",
        CONTINUE_SHOPPING: "Poursuivre les Achats",
        TRY_AGAIN: "Réessayer",
        YOUR_PAYMENT_HAS_FAILED: 'Votre paiement a échoué. Veuillez vérifier les informations et réessayer',
        VIEW_ALL: 'Tout voir',
        CATEGORIES: 'Catégories',
        ENTER_YOUR_EMAIL_ADDRESS: 'Entrer votre adresse email',
        VIEW_MORE: 'Voir plus',
        DISCOUNT: (value) => `Remise (${value})`,
        ORDER_SUMMARY: 'Résumé de la commande',
        DELIVERY_FEE: 'Frais de livraison',
        SUBTOTAL: 'Sous-total',
        OR_CREATE_A_ACCOUNT: "ou créez un compte",
        SAME_BILLING_ADDRESS: "Adresse de facturation identique",
        POSTAL_ADRESS: 'Adresse postale',
        MY_INFORMATION: 'Mes informations',
        YOU_MIGHT_ALSO_LIKE: 'Vous pourrier aussi aimer',
        SIGN_UP_NOW: "S'inscrire maintenant",
        SEARCH_FOR_PRODUCTS: "Rechercher des produits...",

        PRODUCTS: 'Produits',
         EMPTY_THE_CART: "Vider le panier",
        SAVE: 'Enregistrer',
        
        PREVIOUS: 'Précédent',
        NEXT: 'Suivant',
        SELECT: 'Choisir',
        TOTAL_ELEMENTS:(total)=>`${total} produits`,
        SHOWING_PRODUCTS : (page, totalElements) => totalElements ? `Affichage de ${ page.pageNumber * page.pageSize - (page.pageSize - 1) }-${ (page.pageNumber * page.pageSize) > totalElements ? totalElements : (page.pageNumber * page.pageSize) } sur ${totalElements} produits` : `${totalElements} produit${totalElements === 0 ? 's' : ''}`,
       
        GOOD_MORNING: (name) => `Bonjour ${name},`,
        'THE_NEW_ARTICLES_?':(name)=>`Les nouveautés : ${name}`,
        DISCOVER_THE_UNIVERSE :(nameSubUniverses)=>`Découvrez l’univers : ${nameSubUniverses}`,
        ARTICLE_IN_STOCK:'Articles en stock',
        CANCEL_PAYMENT_LINK:"Annuler le lien de paiement",
        EMPTY_PRODUCTS:'Aucun produit disponible',
    },
    ENGLISH: {
        CATEGORIES: 'Categories',
        VIEW_ALL: 'View all',
        DESCRIPTION: "Description",
        FREE_IN_N_HOURS_FROM: (days = 2 ) => `DELIVERY DEADLINE ${days}d`,/** FROM ${MaskOnBlur(price, symbol,precision)} */
        ADD_TO_CART: 'Add to cart',
        REFERENCE: (ref) => `Reference : ${ref}`,
        SIGN_IN: 'Sign in',
        ALREADY_HAVE_AN_ACCOUNT: 'Already have an account ?',
        CREATE: 'Create',
        PASSWORD: 'Password',
        EMAIL: 'Email',
        LAST_NAME: 'Last name',
        FIRST_NAME: 'First name',
        FIELD_REQUIRED: "This field is required",
        INVALID_EMAIL_ADDRESS: 'Invalid email address',
        CUSTOMER_LOGIN: 'Log in',
        NEW_CUSTOMER_SIGN_UP: 'New Customer? Sign Up!',
        ACCOUNT: 'Account', 
        LANGUAGE: 'language',
        ENGLISH: 'English',
        FRENCH: 'French',
        FORGOT_PASSWORD: "Forgot password ?",
        TITLE_FORGOT_PASSWORD: "Forgot password",
        NEXT: "Next",
        ENTER_CODE: "Enter the code",
        CONFIRMATION: "Confirmation",
        CHANGE: "Change",
        PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS: "Password must be at least 6 characters",
        PASSWORDS_ARE_NOT_THE_SAME: "Passwords are not the same",
        PLEASE_CONFIRM_YOUR_NEW_PASSWORD: 'Please confirm your new password',
        NEW_PASSWORD: "New password",
        IS_COMPANY: 'Company',
        COMPANY_NAME: "Company name",
        CELLPHONE: "Cell phone",
        CREATE_AN_ACCOUNT: "Create an account",
        OPERATION_SUCCESS: "Operation completed successfully",
        OPERATION_CHANGE_PASSWORD_SUCCESS: 'Your password has been successfully changed !',
        PRODUCTS_LEFT: (stoke = 0, withNegativeStock = false, articleId) => withNegativeStock && articleId ? 'In stock' : (stoke > 0 && articleId) ? `There are ${stoke} products left` : 'Sold Out',
        SHOP_NOW: "Shop Now",
        SEARCH: "Search",
        PRODUCT: 'Product',
        TOTAL: 'Total',
        QTY: 'Qty',
        REMOVE: 'Remove',
        CHECK_OUT: "Check Out",
        ARTICLE_NOT_FOUND: "Article not found",
        ARTICLE_NOT_ACTIF: "Article not active",
        ARTICLE_NOT_AVAILABLE_FOR_SALE: "Article not available for sale",
        ARTICLE_NOT_AVAILABLE_ONLINE: "Article not available online",
        WEB_ONLINE_START_DATE_BEFORE_TODAY: "Article is not currently available",
        WEB_ONLINE_DEADLINE_EXPIRED: "Article is not currtotalently available",
        ARTICLE_TYPE_NOT_SIMPLE: "Article type not simple",
        ADRESS_1_FORM: "Adress 1",
        ADRESS_2_FORM: "Adress 2",
        ADRESS_3_FORM: "Adress 3",
        POSTAL_CODE_FORM: "Postal Code",
        CITY_FORM: "City",
        COUNTRY: "Country",
        VALIDATE: 'Validate',
        CANCEL: 'No, cancel',
        BILLING_ADDRESS: 'Billing address',
        DELIVERY_ADDRESS: 'Delivery address',
        MY_ACCOUNT: "My account", 
        ECOMMERCE_IS_NOT_CONFIGURED_FOR_THIS_STORE: "E-commerce is not configured",
        SOMETHING_WENT_WRONG: "Oops, something went wrong!",
        REFRESH: "Refresh",
        OPERATION_FAILED: "Operation failed",
        DECLINATION_PRINCIPLE_PRICE: ({ MaskOnBlur = () => null,
            minDeclinationPrinciplePriceInclTax,
            maxDeclinationPrinciplePriceInclTax, symbol, precision }) => `From ${MaskOnBlur(minDeclinationPrinciplePriceInclTax, symbol, precision)} to ${MaskOnBlur(maxDeclinationPrinciplePriceInclTax, symbol, precision)}`,
        DELETE_LIGNE: "Yes, delete the row",
        CANCEL_LIGNE: "No, cancel",
        CONFIRM_DIALOG_TITLE_BLOCK: "Are you sure you want to delete the row ?",
        CONFIRM_VALIDATION_BUTTON: "Yes, validate",
        WOULD_YOU_REALLY_LIKE_TO_CONFIRM_YOUR_CART: "Would you really like to confirm your cart ?",
        YOU_HAVE_NO_ITEMS_IN_YOUR_CART: "You have no items in your cart",
        LOGOUT: "Logout",
        COPY_INTO_THE_DELIVERY_ADDRESS: "Copy into the delivery address",
        NO_TEXT_LABEL: "No options",
        PRODUCT_ADDED_SUCCESSFULLY: "Product added successfully",
        ERROR_AVAILABLE_STOCK: "Stock Unavailable",
        ERROR_SELECT_VARIANT_VALUE: "Select an option from the available list of variable values",
        HOME: "Home",
        CART: "Cart",
        YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED: "Your payment has been successfully processed",
        YOUR_CANCEL_PAYMENT_LINK_HAS_BEEN_SUCCESSFULLY_PROCESSED:"Payment link successfully canceled",
        CONTINUE_SHOPPING: "Continue Shopping",
        TRY_AGAIN: "Try again",
        YOUR_PAYMENT_HAS_FAILED: 'Your payment has failed. Please verify the information and try again',
        ENTER_YOUR_EMAIL_ADDRESS: 'Enter your email address',
        VIEW_MORE: 'View more',
        DISCOUNT: (value) => `Discount (${value})`,
        ORDER_SUMMARY: 'Order Summary',
        DELIVERY_FEE: 'Delivery fee',
        SUBTOTAL: 'Subtotal',
        OR_CREATE_A_ACCOUNT: "or create an account",
        "IS_COMPANY_?": 'Company client?',
        SAME_BILLING_ADDRESS: "Same billing address",
        POSTAL_ADRESS: 'Postal address', 
        MY_INFORMATION: 'My information',
        SAVE:'Save',
        YOU_MIGHT_ALSO_LIKE: 'You might also like',
        SIGN_UP_NOW: 'Sign up now',
        SEARCH_FOR_PRODUCTS: "Search for products...",
        MY_CART: "My cart",
        GOOD_MORNING: (name) => `Good morning ${name},`,
        PRODUCTS: 'Products',
        TOTAL_ELEMENTS:(total)=>`${total} products`,
        SHOWING_PRODUCTS:(page,totalElements)=>totalElements ? `Showing ${ page.pageNumber * page.pageSize - (page.pageSize -1) }-${ (page.pageNumber * page.pageSize) > totalElements ? totalElements : (page.pageNumber * page.pageSize) } of ${totalElements } products`:`${totalElements} product${totalElements === 0 ? 's' :''}`,
    
        VALIDATE_CART: "Validate the cart",
        PROCEED_TO_PAYMENT: "Proceed to payment",
        EMPTY_THE_CART: "Empty the cart",

        PREVIOUS: 'Previous',
        NEXT: 'Next',
        SELECT: 'Choose',
        'THE_NEW_ARTICLES_?':(name)=>`The new articles: ${name}`,

        DISCOVER_THE_UNIVERSE :(nameSubUniverses)=>`Discover the universe : ${nameSubUniverses}`,
        ARTICLE_IN_STOCK:'Articles in stock',
        CANCEL_PAYMENT_LINK:"Cancel payment link",
   
        EMPTY_PRODUCTS:'No products available',
    }
} 