/**
 * Enum-like object representing the available theme options for the application.
 * The `Object.freeze` method is used to make the object immutable.
 *
 * @author Helmi Ben Eroi
 * @date 2024-08-16
 *
 * @type {Object}
 * @property {string} HORIZON - Represents the HORIZON theme.
 * @property {string} PULSE - Represents the PULSE theme. 
 * @property {string} ENUM_CODE - Represents the ENUM_CODE theme.
 */
export const themeEnums = Object.freeze({ 
  HORIZON: 'HORIZON',
  PULSE: 'PULSE',
  SHOPY:"SHOPY"
  });
  
export const keyTemplateSettings = Object.freeze({ 
  bannerHomePagefooter1:'BANNER_HOME_PAGE_FOOTER_1',
  bannerHomePageHeader1:'BANNER_HOME_PAGE_HEADER_1',
  logo:'LOGO', 
  address:'ADDRESS',
  links:'LINKS',
  socialNetworks:'SOCIAL_NETWORKS',
  newsletterSubscribeText:'NEWSLETTER_SUBSCRIBE_TEXT',
  homePageFooterTextMain:'HOME_PAGE_FOOTER_TEXT_MAIN'
});
  


/**
 * articleType
 */
  
export const articleType = Object.freeze({ 
  model:'MODEL', 
simple:'SIMPLE',
declination:'DECLINATION',
regroupement:'REGROUPEMENT'
});
   