


import { TransformToNumber } from "utils";
import api from "../index";
/**
 * Check if a cart exists for a customer ID
 * @returns {Promise} - API response from the GET request.
 * @returns promise
 */
const useCheckIfCartExistsByCustomerId = (id) => api.get(`/dossiers/sales/ecom/customerBasketExistence?id=${id}`);
/**
 * Creation of a sales document
 * @param {number|null} [customerId=null] - The ID of the customer. If null, no customer ID is appended to the URL.  
 * @returns {Promise} - API response from the GET request.
 */
const useCreateSaleDocument = (customerId) => api.post(`/dossiers/sales${customerId ? `?customerId=${customerId}` : ''}`);

/**
 * Retrieve an E-commerce cart based on the customer ID
 * Retrieval of the cart based on the document code
 * @param {string} [params.documentCode=""] - The code of the document/dossier.
 * @returns {Promise} - API response from the GET request.
 */
export const useGetEcommerceBasketFromCustomerIdOrDocumentCode = (documentCode) => api.get(`/dossiers/sales/ecom${documentCode ? `/${documentCode}` : ''}`);
/**
 * Update customer information for a sales dossier.
 *
 * @param {Object} params - Parameters for the request.
 * @param {string} [params.documentCode=""] - The code of the document/dossier.
 * @param {number|null} [params.customerId=null] - The ID of the customer to update. If null, no customer ID is appended to the URL.
 * @returns {Promise} - API response from the PUT request.
 */
export const useUpdateCustomer = ({
    documentCode = "",
    customerId = null
}) => api.put(`/dossiers/sales/current/${documentCode}/customer/update${customerId ? `?customerId=${customerId}` : ''}`);


/**
 * Add an article to a sales dossier by its ID.
 *
 * @param {Object} body - The request body containing article details.
 * @param {string} body.key - The key of the dossier.
 * @param {string} body.articleId - The ID of the article to add.
 * @param {boolean} [body.offered] - Indicates if the article is offered.
 * @param {number} [body.quantity] - The quantity of the article to add.
 * @param {number} [body.unitPrice] - The unit price of the article.
 * @param {string} [body.serialBatchNumberList] - List of serial batch numbers associated with the article.
 * @param {number} [body.lineUnitValue] - The line unit value for the article.
 * @param {string} [body.lineInputType] - The type of line input for the article.
 * @param {string} [body.gtinId] - The GTIN ID of the article.
 * @param {string} [body.gtinCode] - The GTIN code of the article.
 * @param {string} [body.gtinCodeType] - The type of GTIN code.
 * @param {string} [body.gtinType] - The type of GTIN.
 * @param {string} [body.conditioningId] - The ID for the conditioning of the article.
 * @param {number} [body.quantityInConditioning] - Quantity in the conditioning of the article.
 * @param {string} [body.returnCustomerLabelId] - ID for the return customer label.
 * @param {string} [body.returnCustomerLabelValue] - Value for the return customer label.
 * @param {number} [body.stockConditioningQuantity] - Quantity for stock conditioning.
 * @param {boolean} [body.articleOfferedLoyalty] - Indicates if the article is offered for loyalty.
 * @param {string} [body.giftCardNumber] - Gift card number associated with the article.
 * @returns {Promise} - API response from the PUT request.
 */
const useAddArticleById = body => api.put(`/dossiers/sales/current/${body?.key}/article/${body?.articleId}/add?${!!body?.offered ? "offered=true" : ""
    }${body?.quantity ? "&quantity=" + body?.quantity : ""
    }${(body?.unitPrice || 0) > 0 ? "&unitPrice=" + body?.unitPrice : ""
    }${body?.serialBatchNumberList ? "&serialBatchNumberList=" + body?.serialBatchNumberList : ""
    }${body?.lineUnitValue ? "&lineUnitValue=" + body?.lineUnitValue : ""
    }${body?.lineInputType ? "&lineInputType=" + body?.lineInputType : ""
    }${body?.gtinId ? "&gtinId=" + body?.gtinId : ""
    }${body?.gtinCode ? "&gtinCode=" + body?.gtinCode : ""
    }${body?.gtinCodeType ? "&gtinCodeType=" + body?.gtinCodeType : ""
    }${body?.gtinType ? "&gtinCode=" + body?.gtinCode : ""
    }${body?.conditioningId ? "&conditioningId=" + body?.conditioningId : ""
    }${body?.quantityInConditioning ? "&quantityInConditioning=" + body?.quantityInConditioning : ""
    }${body?.returnCustomerLabelId ? "&returnCustomerLabelId=" + body?.returnCustomerLabelId : ""
    }${body?.returnCustomerLabelValue ? "&returnCustomerLabelValue=" + body?.returnCustomerLabelValue : ""
    }${body?.stockConditioningQuantity ? "&stockConditioningQuantity=" + body?.stockConditioningQuantity : ""
    }${body?.articleOfferedLoyalty ? "&articleOfferedLoyalty=" + body?.articleOfferedLoyalty : ""
    }${body?.giftCardNumber ? "&giftCardNumber=" + body?.giftCardNumber : ""
    }`);


/**
 * Update the quantity of a line in a sales dossier.
 *
 * @param {Object} body - The request body containing line quantity update details.
 * @param {string} body.key - The key of the dossier.
 * @param {number} [body.lineIndex] - The index of the line to update.
 * @param {number} [body.quantity] - The new quantity for the line.
 * @param {boolean} [body.updateConditioning] - Indicates if the conditioning should be updated.
 * @param {string} [body.gtinConditioningId] - The GTIN conditioning ID associated with the line.
 * @param {number} [body.quantityInConditioning] - Quantity in the conditioning of the line.
 * @param {string} [body.customerReturnLabelId] - ID for the customer return label.
 * @param {string} [body.customerReturnLabelName] - Name for the customer return label.
 * @returns {Promise} - API response from the PUT request.
 */
export const updateLineQuantity = body => api.put(
    `/dossiers/sales/current/${body.key}/line/quantity/update?lineIndex=${body?.lineIndex
    }${body?.quantity ? "&quantity=" + body?.quantity : ""}${body?.updateConditioning === true
        ? "&updateConditioning=" + body?.updateConditioning
        : ""
    }${body?.gtinConditioningId
        ? "&gtinConditioningId=" + body?.gtinConditioningId
        : ""
    }${body?.quantityInConditioning
        ? "&quantityInConditioning=" + body?.quantityInConditioning
        : ""
    }${body?.customerReturnLabelId
        ? "&customerReturnLabelId=" + body?.customerReturnLabelId
        : ""
    }${body?.customerReturnLabelName
        ? "&customerReturnLabelName=" + body?.customerReturnLabelName
        : ""
    }`,
);

/**
 * Delete a line from the current sales document.
 *
 * @param {Object} data - The request data for deleting a line.
 * @param {string} data.key - The key of the document from which to delete the line.
 * @param {number} data.lineIndex - The index of the line to delete.
 * @returns {Promise} - API response from the PUT request.
 */
export const useDeleteLine = data => api.put(
    `/dossiers/sales/current/${data.key}/line/delete?lineIndex=${data?.lineIndex}`,

);


/**
* Abandon the current sales document.
*
* @param {string} key - The key of the sales document to abandon.
* @param {string} [checkoutSessionId] - Optional ID of the checkout session associated with the abandonment.
* @returns {Promise} - API response from the PUT request.
*/
export const useAbandonSaleDocument = (key, checkoutSessionId) => api.put(`/dossiers/sales/current/${key
    }/abandon` + (checkoutSessionId ? "?checkoutSessionId=" + checkoutSessionId : ''));


 /**
 * Add a payment to the current sales document.
 *
 * @param {Object} data - The request data for adding a payment.
 * @param {string} data.key - The key of the sales document to which the payment is being added.
 * @param {string} [data.paymentMethodId] - Optional ID of the payment method.
 * @param {number} [data.paymentAmount] - The amount of the payment. Must be greater than 0.
 * @param {string} [data.checkoutSessionId] - Optional ID of the checkout session.
 * @param {string} [data.environmentId] - Optional ID of the environment associated with the payment.
 * @param {string} [data.deadline] - Optional deadline for the payment.
 * @param {string} [data.number] - Optional number associated with the payment.
 * @param {string} [data.bcTerminalType] - Optional type of the terminal used for the payment.
 * @param {string} [data.cashRecyclerType] - Optional type of the cash recycler used.
 * @param {string} [data.paymentDetails] - Optional additional details about the payment.
 * @param {boolean} [data.plusPaymentMode] - Optional flag indicating if plus payment mode is used.
 * @param {string} [data.plusPaymentMethodId] - Optional ID of the plus payment method.
 * @param {boolean} [data.negativePaymentMode] - Optional flag indicating if negative payment mode is used.
 * @param {string} [data.negativePaymentMethodId] - Optional ID of the negative payment method.
 * @param {boolean} [data.printPaymentTicket] - Optional flag indicating if a payment ticket should be printed.
 * @param {Object} [data.body] - Optional additional data for the payment.
 * @param {string} [data.body.appVersion] - Optional app version.
 * @param {string} [data.body.clientCardTicket] - Optional client card ticket.
 * @param {string} [data.body.transactionId] - Optional transaction ID.
 * @returns {Promise} - API response from the PUT request.
 */
export const useAddSalePayment = data =>
    api.put(`/dossiers/sales/current/${data.key}/payment/add?${data?.paymentMethodId ? "paymentMethodId=" + data?.paymentMethodId : ""
        }${"&paymentAmount=" + (TransformToNumber(data?.paymentAmount) > 0  ? TransformToNumber(data.paymentAmount) : 0) }${
 data?.plusPaymentMode ? "&plusPaymentMode=" + data?.plusPaymentMode : ""}${
 data?.plusPaymentMethodId ? "&plusPaymentMethodId=" + data?.plusPaymentMethodId : ""}${
 data?.negativePaymentMode ? "&negativePaymentMode=" + data?.negativePaymentMode : ""}${
 data?.negativePaymentMethodId ? "&negativePaymentMethodId=" + data?.negativePaymentMethodId : ""}${
 [false, true].includes(data?.printPaymentTicket) ? '&printPaymentTicket=' + !!data?.printPaymentTicket : ""}${
 !!data?.onlinePaymentMode ? '&onlinePaymentMode=' + data?.onlinePaymentMode : ""}`);
/**
 * Validate the current sales document. 
 *
 * @param {Object} data - The request data for validating the sales document.
 * @param {string} data.key - The key of the sales document to validate.
 * @param {string} [data.checkoutSessionId] - Optional ID of the checkout session associated with the validation.
 * @param {string} [data.environmentId] - Optional ID of the environment associated with the validation.
 * @param {boolean} [data.negativePaymentMode] - Optional flag indicating if negative payment mode is used.
 * @param {string} [data.reimbursementPaymentMethodId] - Optional ID of the reimbursement payment method.
 * @param {boolean} [data.printPaymentTicket] - Optional flag indicating if a payment ticket should be printed.
 * @returns {Promise} - API response from the GET request.
 */
export const useValidateSaleDocument = data => api.get(
    `/dossiers/sales/current/${data?.key}/validate?${
      data?.checkoutSessionId ? "checkoutSessionId=" + data?.checkoutSessionId : ""}${
        data?.environmentId ? "&environmentId=" + data?.environmentId : ""}${
          data?.negativePaymentMode ? "&negativePaymentMode=" + data?.negativePaymentMode : ""}${
            data?.reimbursementPaymentMethodId ? "&reimbursementPaymentMethodId=" + data?.reimbursementPaymentMethodId : ""}${
              data?.printPaymentTicket ? "&printPaymentTicket=" + data?.printPaymentTicket : ""}`);
              
/**
 * Validate the current sales document use the Yavin payment.
 * 
 * @param {*} data - The request data for validating the sales document
 * @param {string} data.key - The key of the sales document to validate.
 * @param {string} [data.subDomain] - Optional subdomain of the sales document.
 * @returns {Promise} - API response from the GET request. 
 */
 export const useCreateYavinPayment=(data)=>api.get(`/dossiers/sales/current/${data?.key}/createYavinPayment?${data?.subDomain ? "subDomain=" + data?.subDomain : ""}`);
/**
 * Cancels a Yavin payment  
 *
 * @param {Object} data - The object containing the sales dossier information.
 * @param {string} data.key - The unique identifier for the sales dossier.
 * @returns {Promise} - The API response promise for the Yavin payment cancellation request.
 */
export const useCancelYavinPayment = (data) => 
    api.get(`/dossiers/sales/current/${data?.key}/cancelYavinPayment`);

 
export const saleAPI = {
    useAddArticleById,//Add an article to a sales document by its ID.
    useCheckIfCartExistsByCustomerId,//Check if a cart exists for a customer ID
    useCreateSaleDocument,// Creation of a sales document
    useGetEcommerceBasketFromCustomerIdOrDocumentCode,// * Retrieve an E-commerce cart based on the customer ID * Retrieval of the cart based on the document code
    useUpdateCustomer,//Update customer information for a sales document.
    updateLineQuantity,//Update the quantity of a line in a sales document.
    useDeleteLine,//Delete a line from the current sales document.
    useAbandonSaleDocument,//Abandon the current sales document.
    useAddSalePayment,//Add a payment to the current sales document.
    useValidateSaleDocument,//Validate the current sales document.
    useCreateYavinPayment,//Validate the current sales document use the Yavin payment.
    useCancelYavinPayment ,//Cancels a Yavin payment  
};
export default saleAPI;