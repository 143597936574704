import { LinearProgress } from "@mui/material"; 
import React, { createContext, lazy, useContext, useEffect, useReducer } from "react";  
import { useGetProductsCategoriesApi, useGetTemplateDataApi } from "template/global/hooks";
import { useCheckIfCartExistsByCustomerId } from "template/global/hooks/sale";
import { sumItemInArrayObject } from "utils"; 
import { toggleTheme, useThemeDispatch } from "./ThemeContext";
  
const GlobalStateContext = createContext();
const GlobalStateDispatchContext = createContext();
/**
 * 
 * Updates the shopping cart based on the provided category and method.
 * If the method is 'DELETE', it removes the category from the cart.
 * Otherwise, it adds or updates the category quantity in the cart.
 * 
 * @param {Object} categorie - The category to be added or removed. Should contain an 'id' and 'qte' (quantity).
 * @param {Object} shoppingCart - The current state of the shopping cart.
 * @param {string} method - The operation to perform on the cart. Should be either 'DELETE' or any other value to add/update.
 * @returns {Object} The updated shopping cart.
 */

const handlerShoppingCart = (categorie, shoppingCart, method) => {
  // Create a copy of the current shopping cart
  let copyShoppingCart = { ...shoppingCart };

  if (method === 'DELETE') {
    // Find the category to be removed
    const shoppingCartCategorie = shoppingCart?.listCategories.find(item => categorie?.id === item?.id);
    // Calculate the total price for the category to be removed
    const totalPriceShoppingCartCategorie = shoppingCartCategorie?.qte * shoppingCartCategorie.principlePriceInclTax;
    // Create a new list without the removed category
    const newList = copyShoppingCart?.listCategories.filter(item => categorie?.id !== item?.id) || [];
    // Update the shopping cart with the new list, total, and number of categories
    copyShoppingCart = {
      ...copyShoppingCart,
      total: copyShoppingCart?.total - totalPriceShoppingCartCategorie,
      listCategories: newList,
      nbCategories: sumItemInArrayObject(newList, 'qte'),
    };
  } else {
    // Find the index of the category in the cart
    const shoppingCartCategorieIndex = shoppingCart?.listCategories.findIndex(item => categorie?.id === item?.id);
    // Create a new copy of the list of categories
    let newList = [...shoppingCart?.listCategories];

    // If the category already exists, update its quantity
    if (shoppingCartCategorieIndex >= 0) {
      newList[shoppingCartCategorieIndex] = {
        ...newList[shoppingCartCategorieIndex],
        qte: (newList[shoppingCartCategorieIndex]?.qte || 0) + categorie?.qte,
      };
    } else {
      // If the category doesn't exist, add it to the list
      newList.push({ ...categorie });
    }

    // Calculate the total price for the added/updated category
    const totalCategorie = categorie?.qte * categorie.principlePriceInclTax;

    // Update the shopping cart with the new list, total, and number of categories
    copyShoppingCart = {
      ...copyShoppingCart,
      total: copyShoppingCart?.total + totalCategorie,
      listCategories: newList,
      nbCategories: sumItemInArrayObject(newList, 'qte'),
    };
  }

  // Return the updated shopping cart
  return copyShoppingCart;
};

/**
 * Updates a specific value in the local storage under the key 'licencesData'.
 * 
 * @param {string} key - The key to be updated in the storage data.
 * @param {any} values - The values to set for the given key.
 */

const setValueByKey = (key, values) => {
  try {
    // Get existing data from localStorage
    let copyData = JSON.parse(localStorage.getItem("licencesData"));
    // Update the specific key for the current shop
    copyData[localStorage.getItem("codeShop")] = {
      ...copyData[localStorage.getItem("codeShop")],
      [key]: values,
    };
    // Save the updated data back to localStorage
    localStorage.setItem("licencesData", JSON.stringify(copyData));
  } catch (error) {
    console.error(error);
  }
};

// Initial value for the shopping cart and license data
const initialValueLicenceData = {
  user:{licences:[],codeShop:null},
  shoppingCart: {
    total: 0,
    listCategories: [],
    nbCategories: 0,
  },
  numberOfAtemps: 0,
  documentCode:null,
  saleDocument:{lines:[],documentCalculationMethod:'',totalGlobalInclVatNetNetDc:0},
};
 
/**
 * Reducer function for managing settings state. 
 *
 * @param {Object} state - The current settings state.
 * @param {Object} action - The action to perform.
 * @param {string} action.type - The type of action.
 * @param {string} action.payload - The value to set for the action type.
 * @returns {Object} The updated settings state.
 */
function settingsReducer(state, action) {
  switch (action.type) { 
    case 'SHOPPING_CART':{ 
      const copyShoppingCart= handlerShoppingCart(action.payload,state?.licenceData.shoppingCart,action.payload?.method); 
      setValueByKey("shoppingCart", copyShoppingCart); 
      return { ...state,licenceData:{...state?.licenceData, shoppingCart: copyShoppingCart} };    
    }
    case 'BASKET_DATA':{
      setValueByKey("documentCode", action.payload?.code);  
      setValueByKey("saleDocument", {...initialValueLicenceData?.saleDocument,...action.payload});  
      return { ...state,licenceData:{...state?.licenceData, saleDocument: {...initialValueLicenceData?.saleDocument,...action.payload},documentCode:action.payload?.code} };    
    }
    case "OPEN_SIDEBAR": 
      return { ...state, openSidebar: !state.openSidebar }; 
   case "MARGIN_TOP": 
        return { ...state, marginTop: action.payload }; 
    case "LANGUAGE":{
      localStorage.setItem("language", JSON.stringify(action.payload));
      return { ...state, language: action.payload }; 
    }    
    case  "NUMBER_OF_ATEMPS":{
      const newdata =  !action?.payload ? (state?.licenceData?.numberOfAtemps || 0) + 1 : 0; 
      setValueByKey("numberOfAtemps",newdata); 
      return { ...state,licenceData:{...state?.licenceData, numberOfAtemps: newdata} };    
      
    }
    case "TEMPLATE_DATA":{    
      return { ...state,...action.payload}; 
    } 
    
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
} 


const BasketProvider = ({state,dispatch}) =>{
  const {isCartExist} = useCheckIfCartExistsByCustomerId(state?.licenceData?.documentCode);
// Import necessary data and methods from custom hooks (useGetProductsCategoriesApi and useGetTemplateDataApi)
const { allProductsCategories , setAllProductsCategories, getProductsCategories } = useGetProductsCategoriesApi((error)=>{

  dispatch({
    type: "TEMPLATE_DATA",
    payload: { 
      errorUpload: error , 
    },
  });
});
const { templateData, setTemplateData, getTemplateData } = useGetTemplateDataApi((error)=>{

  dispatch({
    type: "TEMPLATE_DATA",
    payload: { 
      errorUpload: error , 
    },
  });
});

const themeDispatch = useThemeDispatch();

  // First useEffect hook to trigger product categories and template data fetching when licence token is available
  useEffect(() => {
    // If licenceData contains a token, fetch product categories and template data 
    if (state?.licenceData?.token) {
      getProductsCategories();
      getTemplateData(); 
      isCartExist((state?.licenceData?.user?.licences || []).find(({licenceCode})=>(licenceCode === state?.licenceData?.codeShop))?.ecommerceCustomerId);  
    }
    // eslint-disable-next-line
  }, [state?.licenceData?.token]); // Dependencies ensure this useEffect runs when token changes
  
  // Second useEffect hook to dispatch the fetched data to the state
  useEffect(() => {
    // If both templateData and product categories are available, update the state
    if (templateData && allProductsCategories) { 
      dispatch({
        type: "TEMPLATE_DATA",
        payload: {
          templateData, // Set template data
          categories: allProductsCategories || [], // Set product categories
          isLoading: false, // Loading is set to false when data is loaded 
          errorUpload: false , 
        },
      });
      toggleTheme(themeDispatch,templateData?.templateCode);
    }
  
    //Cleanup function to reset the data when the component unmounts
    return () => {
      if (templateData && allProductsCategories) {
      setTemplateData(null); // Reset template data
      setAllProductsCategories(null); // Reset product categories  
      }
    }; 
    // eslint-disable-next-line
  }, [templateData, allProductsCategories]); // Dependencies ensure this useEffect runs when templateData or allProductsCategories changes
  
return (<> </>);

};



/**
 * Provider component for managing global state.
 * Provides context for settings state and dispatch function.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {JSX.Element} The `GlobalStateProvider` component.
 */
const GlobalStateProvider = ({ children }) => { 


// Initialize state using useReducer. It manages the UI state, loading state, and data such as categories and template data.
const [state, dispatch] = useReducer(settingsReducer, {
  openSidebar: false, // Sidebar is closed by default
  marginTop: "70px", // Initial margin-top for layout
  isLoading: true, // Initially loading is set to true 
  categories: [], // Product categories are empty initially
  numberOfAtemps:JSON.parse(localStorage.getItem("numberOfAtemps")) || 0,
  templateData: {
    deliveryDeadline: 2, // Default delivery deadline set to 2
    currency: { symbol: "€", precision: 2, templateCode: null, templatePages: [], templatesettings: [] },
  },
  language: JSON.parse(localStorage.getItem("language")) || 'FRENCH', // Language is set from localStorage or defaults to 'FRENCH'
  errorUpload:false,
  // Licence data is fetched from localStorage, or initial values are set if not found
  licenceData: localStorage.getItem("licencesData") && JSON.parse(localStorage.getItem("licencesData")) && localStorage.getItem("codeShop")
    ? { ...initialValueLicenceData, ...JSON.parse(localStorage.getItem("licencesData"))[localStorage.getItem("codeShop")] }
    : initialValueLicenceData,

});



  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalStateDispatchContext.Provider value={dispatch}>  
      <BasketProvider state={state} dispatch={dispatch}/>
       {(!state?.isLoading || !state?.licenceData?.token || !!state?.errorUpload) ? <>
        {children} </> : <>  <LinearProgress color="inherit" /> </>}
      </GlobalStateDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

/**
 * Hook to access the settings state.
 * Throws an error if used outside of `GlobalStateProvider`.
 *
 * @returns {Object} The settings state.
 */
const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};

/**
 * Hook to access the settings dispatch function.
 * Throws an error if used outside of `GlobalStateProvider`.
 *
 * @returns {Function} The settings dispatch function.
 */
const useGlobalStateDispatch = () => {
  const context = useContext(GlobalStateDispatchContext);
  if (context === undefined) {
    throw new Error("useGlobalStateDispatch must be used within a GlobalStateProvider");
  }
  return context;
};
 
/**
 * Dispatches an action to update the global state. 
 *
 * @param {Function} dispatch - The dispatch function from `useReducer`.
 * @param {Object} payload - The payload to update in the state.
 */
const setGlobalStateOpenSidebar = (dispatch, payload) => {  
  dispatch({
    type: "OPEN_SIDEBAR",
    payload: payload,
  });
};
/**
 * Dispatches an action to update the global state. 
 *
 * @param {Function} dispatch - The dispatch function from `useReducer`.
 * @param {Object} payload - The payload to update in the state.
 */
const setGlobalStateMarginTop = (dispatch, payload) => {  
  dispatch({
    type: "MARGIN_TOP",
    payload: payload,
  });
};

const setShoppingCart = (dispatch, payload) => {  
  dispatch({
    type: "SHOPPING_CART",
    payload: payload,
  });
};
const setLanguage = (dispatch, payload) => {  
  dispatch({
    type: "LANGUAGE",
    payload: payload,
  });
};
const setNumberOfAtemps = (dispatch, payload) => {  
  dispatch({
    type: "NUMBER_OF_ATEMPS",
    payload: payload,
  });
};
const setBasket = (dispatch, payload) => {  
  dispatch({
    type: "BASKET_DATA",
    payload: payload,
  });
};

export { 
   GlobalStateProvider, 
   useGlobalState, 
   useGlobalStateDispatch,
   setGlobalStateOpenSidebar,
   setGlobalStateMarginTop,
   setShoppingCart,
   setLanguage,
   setNumberOfAtemps,setBasket};
