import { useGlobalState } from "context/GlobalStateContext"; 
import { useNavigate, useParams } from "react-router-dom"; 
import translation from "template/global/translation";
import { isEqual ,isEmpty} from "loadsh";
export const validatePositiveNumber = (elem, lastValid = "", index = 2) => {
  elem = elem?.toString().replace(".", ",");
  // input mask which allows only numbers , one comma and the numbers of decimal depends of index
  // if the value not valid will return the last valid value
  // new RegExp(/^\d*\.?\d{0,2}$/);
  let test = index ? index : 0;
  var pattern1 = "^\\d*";
  var pattern2 = `\\,?\\d{0,${index}}$`;
  // ^\d*\,?\d{0,2}$
  var pattern3 = "^\\d*$";
  let res = !test ? pattern3 : pattern1 + pattern2;

  var validNumber = new RegExp(res);
  if (validNumber.test(elem)) {
    return elem;
  } else {
    return lastValid;
  }
};

export function fullValidateNumber(
  elem,
  lastValid = "",
  index = 2,
  onlyPositive = false,
) {
  elem = elem.replace(".", ",");
  if (onlyPositive) return validatePositiveNumber(elem, lastValid, index);
  let test = index ? index : 0;
  var pattern1 = "^-?\\d*";
  var pattern2 = `\\,?\\d{0,${index}}$`;
  // ^\d*\,?\d{0,2}$
  var pattern3 = "^-?\\d*$";

  let res = !test ? pattern3 : pattern1 + pattern2;
  var validNumber = new RegExp(res);
  if (validNumber.test(elem) || elem === "-") {
    return elem;
  } else {
    return lastValid;
  }
}

export const MaskOnBlur = (elem = "", symbol = "", index = 2, empty) => {
  let repeatZero = "0".repeat(index);
  // in case of elem is null => prams initialization is not working
  if (elem === null) {
    elem = "";
  }
  // first case the input is empty or 0 must add the symbol and initial it
  // must add all the zero after the ',' depends of index
  if ((elem === "" || elem === "0") && !empty) {
    return index === 0 ? `0 ${symbol}` : `0,${repeatZero} ${symbol}`;
  } else if (
    (elem === "" || parseFloat(elem.toString().replace(",", ".")) === 0
      ? true
      : false) &&
    empty === "empty"
  ) {
    return "";
  } else {
    // The event of onBlur , the input must have spaces of each 3 numbers and add the symbol and use "," not "."
    let symb = symbol ? ` ${symbol}` : "";
    // will be match the two parts the first before the "," and the second part afte the ","
    let splitted = elem
      .toString()
      .replace(".", ",")
      .split(",");
    let zeroN = splitted[1]
      ? index - splitted[1].length === 0
        ? 0
        : index - splitted[1].length
      : index;
    let finalZero = "0".repeat(zeroN < 0 ? 0 : zeroN); // handle the case of the index of inputMask > of index Maskblur and value ll be negative
    splitted[1] =
      zeroN < 0
        ? splitted[1].substring(0, splitted[1].length + zeroN)
        : splitted[1]; //If the value is after the comma > index, the numbers must be decremented
    // if the value after the comma not equal to the index , must add at the end N times 0 , to make all the numbers same level
    let value = splitted[1]
      ? "," + splitted[1] + finalZero
      : index === 0
        ? ""
        : "," + repeatZero;
    return (
      splitted[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
      value +
      symb
    );
  }
};

export const TransformToNumber = elem => {
  // parse to float and delete the spaces and make off all the treatments of the masks
  elem = elem ? elem : 0;
  return parseFloat(
    elem
      .toString()
      .replaceAll(",", ".")
      .replaceAll(" ", ""),
  );
};


export const sumItemInArrayObject = (array, itemName, initialValue = 0) => (array || []).reduce((accumulator, item) => TransformToNumber(accumulator) + TransformToNumber(item[itemName] || 0), initialValue);

export const getTranslation = (language, key = '', translationFile = null) => {
  let localTranslation = translationFile || translation;
  try {

    if (!localTranslation[language][key]) return translationFile ? false : key;

    return localTranslation[language][key];

  } catch (error) {
    return key;
  }

};

export function capitalizeFirstLetters(text) {
  return text.replace(/\b\w/g, (match) => match.toUpperCase());
}

export const isGlobalLoadingState = () => {
  return localStorage.getItem('isLoading');
}

export const setGlobalLoadingState = (bool) => {
  localStorage.setItem('isLoading', bool);
}
/**
 * A custom hook to handle navigation within the app, accommodating subdomain logic.
 * @returns {Object} - An object containing navigation helper functions.
 */
export const useLocalNavigate = () => {
  const navigate = useNavigate();
  const { codeShop } = useParams();
  return {
    /**
         * Navigates to a specified link, considering whether the app uses a subdomain or not.
         * @param {string} [link=''] - The relative path to navigate to. Defaults to an empty string.
         */
    appsNavigate: (link = '') => navigate(`${!haveSubDomaine() ? '/' + codeShop : ''}${link}`)
  }
}


export const calculateDateDifference = (timestamp) => {
  // Convert the timestamp to a date object
  const timestampDate = new Date(timestamp);


  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMs = timestampDate - currentDate;

  // Convert the difference to days, hours, minutes, and seconds
  const differenceInSeconds = Math.floor(differenceInMs / 1000);
  const seconds = differenceInSeconds % 60;
  const minutes = Math.floor(differenceInSeconds / 60) % 60;
  const hours = Math.floor(differenceInSeconds / 3600) % 24;
  const days = Math.floor(differenceInSeconds / 86400);

  return {
    differenceInSeconds,
    days,
    hours,
    minutes,
    seconds
  };
}

export const sortArrayOfobjectByNumber = (array, numberKey) => array?.sort((a, b) => a[numberKey] - b[numberKey]);


export const reformatPostalCodes = postalCodes =>
  postalCodes?.map(code => ({
    value: code?.value,
    label: code?.name,
    countryId: code?.countryId,
  }));

export const validateNumber = (elem, lastValid = "", index = 2) => {
  elem = elem.replace(".", ",");
  // input mask which allows only numbers , one comma and the numbers of decimal depends of index
  // if the value not valid will return the last valid value
  // new RegExp(/^\d*\.?\d{0,2}$/);
  let test = index ? index : 0;
  var pattern1 = "^\\d*";
  var pattern2 = `\\,?\\d{0,${index}}$`;
  // ^\d*\,?\d{0,2}$
  var pattern3 = "^\\d*$";
  let res = !test ? pattern3 : pattern1 + pattern2;

  var validNumber = new RegExp(res);
  if (validNumber.test(elem)) {
    return elem;
  } else {
    return lastValid;
  }
};

/**
*
* @param {*} array: The given array you want to sort
* @param {*} key: The key sort is based on
* @returns
*/
export const customSorter = (array, key) =>
  array.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));

export const extractAddresses = addresses => addresses.split("\n");

/**
 * A custom hook that provides pre-configured parameters for API handlers.
 * @returns {Object} - A collection of functions to generate API parameters.
 */
export const useHandlerParamsApi = () => {
  // Destructure `documentCode` from the global state
  const { licenceData: { documentCode } } = useGlobalState();


  return {
    /**
       * Generates parameters for updating line quantity.
       * @param {Object} body - The additional parameters to include in the API call.
       * @returns {Object} - The API parameters with the document code and provided body.
       */
    updateLineQuantityParams: (body) => ({
      key: documentCode,
      ...body
    }),
    /**
        * Generates parameters for validating a sale document.
        * @param {Object} body - The additional parameters to include in the API call.
        * @returns {Object} - The API parameters with the document code and provided body.
        */
    validateSaleDocumentParams: (body) => ({
      key: documentCode,
      ...body
    }),

    /**
     * Retrieves the document code for abandoning a sale document.
     * @returns {string} - The document code.
     */
    abandonSaleDocumentParams: () => documentCode,
    /**
      * Generates parameters for deleting a line.
      * @param {number} lineIndex - The index of the line to delete.
      * @returns {Object} - The API parameters with the line index and document code.
      */
    deleteLineParams: (lineIndex) => ({ lineIndex, key: documentCode, }),
    /**
         * Generates parameters for handling article operations.
         * @param {Object} body - The additional parameters to include in the API call.
         * @returns {Object} - The API parameters with the document code and provided body.
         */
    articleParams: (body) => {
      return ({
        lineInputType: 'NORMAL_GTIN_SELECTION',
        key: documentCode,
        quantity:1,
        ...body
      })
    },
    addSalePaymentParams: (body) => {
      return ({ 
        key: documentCode,
        ...body
      })
    }
  }

};

export const openNewWindowByUrl = (URL) => {
  window.open(URL).focus();
}
/**
 * Checks if the current hostname includes the substring 'fidolia'.
 * @returns {boolean} - `true` if 'fidolia' is found in the hostname, otherwise `false`.
 */
export const isFidolia = () => (document.location.hostname || "").toLowerCase().includes('fidolia');
/**
 * Determines if the current hostname has a subdomain by comparing it against the base URIs.
 * @returns {boolean} - `true` if the hostname has a subdomain, otherwise `false`.
 */
export const haveSubDomaine = () => !isEqual(`.${(document.location.hostname || "").toLowerCase()}`, isFidolia() ? process.env.REACT_APP_BASE_URI_SHOP_FIDOLIA : process.env.REACT_APP_BASE_URI_SHOP_MYPE)
/**
 * Extracts the e-commerce subdomain from the hostname based on the base URI.
 * @returns {string|null} - The subdomain if it exists and matches the base URI, otherwise `null`.
 */
export const getEcomSubDomainFromBaseURI = () => isFidolia() ?
    haveSubDomaine() && document.location.hostname.toLowerCase().split(process.env.REACT_APP_BASE_URI_SHOP_FIDOLIA)[0].split(".")[0] :
    haveSubDomaine() && document.location.hostname.toLowerCase().split(process.env.REACT_APP_BASE_URI_SHOP_MYPE)[0].split(".")[0];
    
/**
 * Converts an object into an array of key-value pair objects.
 * @param {Object} obj - The object to convert.
 * @returns {Array<{key: string, value: any}>} - An array of objects, each containing `key` and `value`.
 */

export const convertObjectToArray = (obj) => {
  return (Object.entries(obj) || [])?.map(([key, value]) => {
    return { key, value };
  });
};
/**function to get the value of a query parameter from a URL.
 * @param {string} url - The URL to extract the query parameter value from.
 * @param {string} paramName - The name of the query parameter to retrieve.
 * @returns {string|null} - The value of the query parameter, or `null` if not found.
 */ 
export function getQueryParamValue(url, paramName) {
  const urlParams = new URLSearchParams(new URL(url, window.location.origin).search);
  return urlParams.get(paramName);
}
export const getCategoryById = (categories = [], id) => {
  for (const category of categories) {
    if (category?.id == id) {
      return category;
    }
    if (category?.children?.length > 0) {
      const foundInChildren = getCategoryById(category.children, id);
      if (foundInChildren) {
        return foundInChildren;
      }
    }
  } 
};
export const FooterCopyRight = () => isFidolia() ? <span
onClick={() => { window.open("https://fidolia.fr/").focus() }}
style={{ fontWeight: 700, cursor: 'pointer', textDecoration: "underline" }}
>Fidolia</span> : <span
onClick={() => { window.open("https://www.mybesolutions.com/").focus() }}
style={{ fontWeight: 700, cursor: 'pointer', textDecoration: "underline" }}
>Mybe Solutions</span>