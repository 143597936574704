
import {api} from "../index";
/**
 * function to get all products
 * @returns {Promise} The promise that resolves with the response data.
 */
export const useGetProductsCategories=()=> api.get('/dossiers/articleUniverses/ecom?sort=orderNumber,asc' ); 
 
/**
 * function to get all products by parent universe id
 * @param {number} parentUniverseId parent universe id
 * @returns {Promise} The promise that resolves with the response data.
 */

export const usePromiseGetCategoriesByParentUniverseId=(parentUniverseId)=> api.get(`/dossiers/articleUniverses/ecom/?${parentUniverseId ? `parentUniverseId=${parentUniverseId}` :''}&sort=orderNumber,asc` ); 
