import axios from 'axios';
import { useEffect, useContext, useReducer } from "react";
import { ErrorAndLoadingContext } from "context/ErrorHandlingAndLoading";
import { getTranslation } from 'utils';
import errorTranslation from "template/global/translation/errorTranslation";
import { setBasket, useGlobalStateDispatch } from 'context/GlobalStateContext';
export const test_api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
  },
});

const api = axios.create({
  baseURL: process.env.REACT_APP_API_LINEOSOFT_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
  },
});


api.interceptors.request.use(
  (config) => {
    const { codeShop, licencesData } = localStorage;
    let token = '';

    try {
      token = JSON.parse(licencesData)[codeShop]?.token;
    } catch (error) {
    }


    // const codeShop = localStorage.getItem("codeShop") || undefined;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["X-LICENCE-CODE"] = codeShop;
    }
    return config;
  },
  (error) => Promise.reject(error),
);


const states = {
  loading: "loading",
  failure: "failure",
  success: "success",
};

function reducer(state, action) {
  switch (action.type) {
    case states.loading:
      return {
        ...state,
        loading: true,
      };
    case states.success:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case states.failure:
      return {
        ...state,
        loading: false,
        error: action.result,
      };
    default:
      return state;
  }
}
export function usePromise(
  promiseFn,
  { onSuccess, onFailure, isEffect = false, effectCondition = [] } = {},
) {

  const initialState = {
    loading: false,
    result: undefined,
    error: undefined,
  };

  const loadingContextAndErrorContext = useContext(ErrorAndLoadingContext);
  const globalStateDispatch = useGlobalStateDispatch();
  const language = JSON.parse(localStorage.getItem("language")) || 'FRENCH';
  const [{ error, result, loading }, dispatch] = useReducer(
    reducer,
    initialState,
  );
  const request = (...args) => {
    dispatch({ type: states.loading });
    loadingContextAndErrorContext.onLoading(true);

    !!args &&
      promiseFn(...args)
        .then(response => {
          dispatch({ type: states.success, result: response });

    loadingContextAndErrorContext.onLoading(false);
          onSuccess && onSuccess(response, args);
          localStorage.setItem("maxRefreshToken", 0);
        })
        .catch(error => {
          dispatch({ type: states.failure, result: error });
          loadingContextAndErrorContext.onLoading(false);

          var code = error?.data?.code || error?.response?.data?.code || null;
          if ((code || []).includes('SMTP') && (code || []).includes('CANNOT_BE_NULL') && code.lenght > 20)
            code = "SMTP_?_CANNOT_BE_NULL"

          if (["ECOM_SALE_BASKET_NOT_FOUND"].includes(code))
            setBasket(globalStateDispatch, null);
 
          //open,msg,severity
          if (code) {
            const labelError = getTranslation(language, code, errorTranslation) || getTranslation(language, 'OPERATION_FAILED', errorTranslation);
            loadingContextAndErrorContext.snackbar(true, labelError, 'error');
          }

          if (!code && ![401, 403].includes((error?.response || error)?.status))
            loadingContextAndErrorContext.snackbar(true, getTranslation(language, 'OPERATION_FAILED'), 'error');
          onFailure && onFailure(error?.response || error, args);

        })
        .finally(() => { });
  };
  // eslint-disable-next-line
  useEffect(isEffect ? request : () => void 0, effectCondition);
  return {
    request,
    result,
    error,
    loading,
  };
}

export { api };
export default api;