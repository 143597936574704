import { LinearProgress } from "@mui/material";
import AlertSnackbar from "components/AlertSnackbar/index";
import {useState, createContext} from "react";   
export const ErrorAndLoadingContext = createContext();
 
function ErrorHandlingAndLoading({ children }) {
  // State for managing snackbar (alert) data
  const [alertSnackbarData, setAlertSnackbarData] = useState({
    msg: 'Operation successful', // Default message
    open: false, // Snackbar visibility
    severity: "success", // Default severity (success message)
    isLoading:false
  });

  return (
    <ErrorAndLoadingContext.Provider
      value={{
        // Function to open the snackbar with custom values
        snackbar: (open, msg, severity) => {
          setAlertSnackbarData((old)=>({...old, open, msg, severity }));
        },
        onLoading:(isLoading)=>{
          setAlertSnackbarData((old)=>({...old,isLoading }));

        }
      }}
    >
{     alertSnackbarData?.isLoading && <LinearProgress  sx={{
      position:'absolute', 
      zIndex: 1400,
      top: 0,
      width: '100%',
 
      }}/>}
      {/* Render the snackbar component */}
      <AlertSnackbar
        open={alertSnackbarData?.open} // Control if the snackbar is open
        msg={alertSnackbarData?.msg} // Display the message
        severity={alertSnackbarData?.severity} // Set the severity (success, error, etc.)
        setOpen={() => {
          // Function to close the snackbar
          setAlertSnackbarData((old) => ({ ...old, open: false }));
        }}
      />
      
      {/* Render the nested child components */}
      { children }
    </ErrorAndLoadingContext.Provider>
  );
}

export default ErrorHandlingAndLoading;
