import { ThemeProvider } from '@mui/material/styles';
import React, { createContext, useContext, useReducer } from "react";
import getTheme from "theme/theme";
import { themeEnums } from "utils/enums";
import CssBaseline from '@mui/material/CssBaseline';

const ThemeStateContext = createContext();
const ThemeDispatchContext = createContext();

/**
 * Reducer function for managing theme state.
 * Handles toggling between light and dark themes.
 *
 * @param {Object} state - The current theme state.
 * @param {Object} action - The action to perform.
 * @param {string} action.type - The type of action.
 * @param {string} action.payload - The theme to switch to.
 * @returns {Object} The updated theme state.
 */
function themeReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_THEME":
      localStorage.setItem("appTheme", action.payload);
      return { ...state, appTheme: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

/**
 * Provider component for managing theme state and dispatch.
 * Uses `ThemeProvider` from MUI to apply the theme to the application.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {JSX.Element} The `CustomThemeProvider` component.
 */
const CustomThemeProvider = ({ children }) => { 
 

  const [state, dispatch] = useReducer(themeReducer, {
    appTheme: localStorage.getItem("appTheme") || themeEnums.HORIZON, 
  });  

  return (
    <ThemeStateContext.Provider value={state}>
      <ThemeDispatchContext.Provider value={dispatch}>
        <ThemeProvider theme={getTheme(state.appTheme)}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  );
};

/**
 * Hook to access the theme state.
 * Throws an error if used outside of `CustomThemeProvider`.
 *
 * @returns {Object} The theme state.
 */
const useThemeState = () => {
  const context = useContext(ThemeStateContext);
  if (context === undefined) {
    throw new Error("useThemeState must be used within a CustomThemeProvider");
  }
  return context;
};

/**
 * Hook to access the theme dispatch function.
 * Throws an error if used outside of `CustomThemeProvider`.
 *
 * @returns {Function} The theme dispatch function.
 */
const useThemeDispatch = () => {
  const context = useContext(ThemeDispatchContext);
  if (context === undefined) {
    throw new Error("useThemeDispatch must be used within a CustomThemeProvider");
  }
  return context;
};

/**
 * Toggles between light and dark themes.
 * Dispatches an action to change the theme based on the current value in local storage.
 *
 * @param {Function} dispatch - The dispatch function from `useReducer`.
 */
const toggleTheme = (dispatch,payload) => {  
  console.log(payload , payload )
  dispatch({
    type: "TOGGLE_THEME",
    payload: payload && themeEnums[payload] ? themeEnums[payload] : themeEnums.HORIZON 
  });
};

/**
 * Sets the theme to a specific preference.
 * Dispatches an action to change the theme to the provided preference.
 *
 * @param {Function} dispatch - The dispatch function from `useReducer`.
 * @param {Object} themePreference - The preferred theme.
 * @param {string} themePreference.value - The theme to set.
 */
const setTheme = (dispatch, themePreference) => {
  dispatch({
    type: "TOGGLE_THEME",
    payload: themePreference.value, 
  });
};

export { CustomThemeProvider, useThemeState, useThemeDispatch, toggleTheme, setTheme };
